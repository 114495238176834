import { query } from '@urql/svelte'

export const runQuery = async (queryStore: OperationStore): Promise<void> => {
    return new Promise(resolve => {
        // there is a common race condition, this should be a promise, but it isn't
        // so we're handling a short delay on all code that awaits runQuery()
        query(queryStore)

        setTimeout(() => {
            resolve()
        }, 400)
    })
}

export const runQueryWithPromise = async (queryDocument: DocumentNode, payload: SomeObject, client: GraphqlClient): Promise<OperationResult> => {
    return await client.query(queryDocument, payload, { requestPolicy: 'network-only' }).toPromise()
}

export const runMutation = async (mutationDocument: DocumentNode, payload: SomeObject, client: GraphqlClient): Promise<OperationResult> => {
    return await client.mutation(mutationDocument, payload).toPromise()
}

export const getPastWinnersFilterQuery = (category: Maybe<string>) => {
    switch (category?.replace(/[0-9]/g, '')) {
        case 'cat':
            return {
                categories: {
                    past_winners_category: {
                        id: {
                            _eq: parseFloat(category.replace('cat', '')),
                        },
                    },
                },
            }

        case 'year':
            return {
                year: {
                    past_winners_year: {
                        id: {
                            _eq: parseFloat(category.replace('year', '')),
                        },
                    },
                },
            }
        default:
            return null
    }
}

export const getJudgesFilterQuery = (category: Maybe<string>) => {
    switch (category?.replace(/[0-9]/g, '')) {
        case 'year':
            return {
                years: {
                    judges_year: {
                        id: {
                            _eq: parseFloat(category.replace('year', '')),
                        },
                    },
                },
            }
        default:
            return null
    }
}
